import React, { useContext, useEffect } from "react";
import "./About.css";
import { Link } from "react-router-dom";
import AboutUsSectionImg from "../../utils/study-2.jpg";
import ChooseSection from "../../components/ChooseSection/ChooseSection";
import AOS from "aos";
import "aos/dist/aos.css";
import { requestContext } from "../../Context/Requests/RequestContext";

function About() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  const { gallery, GalleryLoading } = useContext(requestContext);

  // Skeleton loader for the gallery section
  const GallerySkeleton = () => (
    <div className="row g-4">
      {[...Array(9)].map((_, index) => (
        <div
          key={index}
          className="col-md-4"
          data-aos="zoom-in"
          data-aos-delay={index * 100}
        >
          <div
            className="skeleton-gallery-item"
            style={{
              minHeight: "556px",
              background: "#ddd",
              width: "-webkit-fill-available",
            }}
          />
        </div>
      ))}
    </div>
  );

  return (
    <div className="about-page" style={{ overflowX: "hidden" }}>
      {/* Header Section */}
      <header className="height-75">
        <div
          className="container h-100 d-flex flex-column align-items-center justify-content-center text-light"
          data-aos="fade-down"
        >
          <h1 className="text-center fw-semibold">About Sulazi Academy</h1>
          <p
            className="text-center w-75 mb-5"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            Achieving Excellence Together – Sulazi Academy provides a
            Christ-centered education that transforms lives. Established in 2010
            as a home school, we opened to the public in 2021 and continue to
            grow in excellence.
          </p>
        </div>
      </header>

      {/* Study with Us Section */}
      <div className="container my-5">
        <div className="row">
          <div
            className="col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0"
            data-aos="fade-right"
          >
            <h2 className="mb-4 mb-lg-5">Study with us</h2>
            <p>
              Sulazi Academy offers a unified family environment, promoting
              shared values and peer relationships. Our seasoned staff guide
              students through both academics and extracurricular activities,
              fostering holistic development.
            </p>
            <p className="mb-4 mb-lg-5">
              We use the Accelerated Christian Education (ACE) curriculum, which
              is individualized, Christ-centered, and develops character. The
              program focuses on returning to basic skills, individuality,
              parental involvement, the Bible, and God.
            </p>
            <Link to="/contact">
              <button
                type="button"
                className="btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0"
                data-aos="zoom-in"
              >
                Contact Us
              </button>
            </Link>
          </div>
          <div
            className="col-lg-6 d-flex justify-content-center"
            data-aos="fade-left"
          >
            <img
              src={AboutUsSectionImg}
              className="img-fluid w-75"
              alt="Sulazi Academy"
            />
          </div>
        </div>
      </div>

      {/* Choose Section */}
      <div className="bg-dark text-light py-5" data-aos="fade-up">
        <ChooseSection />
      </div>

      {/* Gallery Section */}
      <div className="bg-body-tertiary py-5">
        <div className="container">
          <h2 className="text-center mb-5" data-aos="fade-up">
            Our Gallery
          </h2>
          {/* Show skeleton if loading, otherwise show gallery */}
          {GalleryLoading ? (
            <GallerySkeleton />
          ) : (
            <div className="row g-4">
              {gallery?.map((person, index) => (
                <div
                  key={index}
                  className="col-md-4"
                  data-aos="zoom-in"
                  data-aos-delay={index * 100}
                >
                  <img
                    src={person?.img?.data_url}
                    className="img-fluid"
                    alt={`Gallery ${person?.id}`}
                    style={{
                      minHeight: "556px",
                      maxHeight: "556px",
                      objectFit: "cover",
                      width: "-webkit-fill-available",
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default About;
