import React, { useEffect } from "react";
import "./Contact.css";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import AOS from "aos";
import "aos/dist/aos.css";

function Contact() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="contact-page">
      {/* Header Section */}
      <header className="height-75">
        <div
          className="container h-100 d-flex flex-column align-items-center justify-content-center text-light"
          data-aos="fade-down"
        >
          <h1 className="text-center fw-semibold">Get In Touch</h1>
          <p
            className="text-center w-75 mb-5"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            We would love to hear from you! For any inquiries, please reach out
            via phone or email. We are located at Plot 1014 Off American
            International School Street, Durmi, Abuja. You can contact us at
            08033142998 or 09055558955, or email us at Sulaziacademy@gmail.com.
          </p>
        </div>
      </header>

      {/* Form Section */}
      <div className="container my-5 d-flex justify-content-center">
        <Form id="contact-form" data-aos="zoom-in">
          <Row className="mb-3" data-aos="fade-up" data-aos-delay="100">
            <Col sm={12} md={6} className="mb-3 mb-md-0">
              <Form.Label>First Name</Form.Label>
              <Form.Control placeholder="First name" />
            </Col>
            <Col sm={12} md={6}>
              <Form.Label>Last Name</Form.Label>
              <Form.Control placeholder="Last name" />
            </Col>
          </Row>

          <Form.Group className="mb-3" data-aos="fade-up" data-aos-delay="200">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" placeholder="Enter email" />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3" data-aos="fade-up" data-aos-delay="300">
            <Form.Label>Address</Form.Label>
            <Form.Control placeholder="Your address" />
          </Form.Group>

          <Row className="mb-3" data-aos="fade-up" data-aos-delay="400">
            <Col sm={12} md={6} className="mb-3 mb-md-0">
              <Form.Label>Location</Form.Label>
              <Form.Select defaultValue="London">
                <option>London</option>
                <option>Manchester</option>
                <option>Liverpool</option>
              </Form.Select>
            </Col>
            <Col sm={12} md={6}>
              <Form.Label>Postcode</Form.Label>
              <Form.Control placeholder="Postcode" />
            </Col>
          </Row>

          <Form.Group className="mb-3" data-aos="fade-up" data-aos-delay="500">
            <Form.Label>Your Message</Form.Label>
            <Form.Control as="textarea" rows={3} />
          </Form.Group>

          <Button
            variant="danger btn-lg"
            type="submit"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default Contact;
