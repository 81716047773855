import React, { useEffect } from "react";
import "./Courses.css";
import { Card } from "react-bootstrap";
import KindergartenImg from "../../utils/images/kindergarten.jpg";
import PrimaryImg from "../../utils/images/primary.jpg";
import SecondaryImg from "../../utils/images/secondary.jpg";
import FaqAccordion from "../../components/FaqAccordion/FaqAccordion";
import AOS from "aos";
import "aos/dist/aos.css";

const courses = [
  {
    id: 1,
    img: [KindergartenImg],
    title: "Kindergarten (Ages 2-5)",
    description:
      "Our Kindergarten program is designed to provide a Christ-centered, character-developing foundation for our youngest learners.",
  },
  {
    id: 2,
    img: [PrimaryImg],
    title: "Primary School (Ages 6-12)",
    description:
      "In our primary school, we focus on building basic skills with a focus on individual learning through our Accelerated Christian Education (ACE) curriculum.",
  },
  {
    id: 3,
    img: [SecondaryImg],
    title: "Secondary School (Ages 13-18)",
    description:
      "Our secondary school prepares students for higher education with an individualized, Christ-centered learning approach, emphasizing character and academic excellence.",
  },
];

function Courses() {
  // Initialize AOS
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="courses-page">
      {/* Header Section */}
      <header className="height-75">
        <div
          className="container h-100 d-flex flex-column align-items-center justify-content-center text-light"
          data-aos="fade-down"
        >
          <h1 className="text-center fw-semibold">Our Programs</h1>
          <p className="text-center w-75 mb-5">
            At Sulazi Academy, we offer a Christ-centered education through an
            Accelerated Christian Education (ACE) curriculum for students from
            Kindergarten to Secondary School.
          </p>
        </div>
      </header>

      {/* Courses Section */}
      <div className="container py-5">
        <div className="row g-4">
          {courses.map((course, index) => (
            <div
              key={course.id}
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-delay={index * 200} // Staggered animation
            >
              <Card className="text-white shadow scale-hover-effect">
                <Card.Img src={course.img} style={{ maxHeight: "423px" }} />
                <Card.ImgOverlay className="d-flex flex-column align-items-center justify-content-center p-md-5">
                  <Card.Title className="fs-1 text-danger">
                    {course.title}
                  </Card.Title>
                  <Card.Text className="text-center">
                    {course.description}
                  </Card.Text>
                </Card.ImgOverlay>
              </Card>
            </div>
          ))}
        </div>
      </div>

      {/* FAQ Section */}
      <div className="bg-dark text-light py-5" data-aos="fade-up">
        <FaqAccordion />
      </div>
    </div>
  );
}

export default Courses;
