import React, { createContext, useState, useEffect } from "react";

export const requestContext = createContext({
  gallery: [],
  BlogLoading: false,
  GalleryLoading: false,
});

export const RequestContextProvider = ({ children }) => {
  const [blog, setBlog] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [BlogLoading, setBlogLoading] = useState(true);
  const [GalleryLoading, setGalleryLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 6;

  const fetchBlog = async () => {
    try {
      setBlogLoading(true);
      function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(";").shift();
      }
      const token = localStorage.getItem("token") || getCookie("jwtToken");

      const response = await fetch("https://api.sulaziacademy.com/blog", {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `LandD ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setBlog(data); // Set the blog state with the fetched data here

        setBlogLoading(false);
      }
    } catch (error) {
      console.error("Error fetching blog data:", error);

      if (retryCount < maxRetries) {
        // Retry fetching blog data with exponential backoff (optional)
        const backoffTime = Math.pow(2, retryCount) * 1000; // Exponential backoff
        setTimeout(() => {
          setRetryCount(retryCount + 1);
          fetchBlog();
        }, backoffTime);
      }
    }
  };

  const fetchGallery = async () => {
    try {
      setGalleryLoading(true);
      function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(";").shift();
      }
      const token = localStorage.getItem("token") || getCookie("jwtToken");

      const response = await fetch("https://api.sulaziacademy.com/gallery", {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `LandD ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setGallery(data); // Set the gallery state with the fetched data here

        setGalleryLoading(false);
      }
    } catch (error) {
      console.error("Error fetching gallery data:", error);

      if (retryCount < maxRetries) {
        // Retry fetching gallery data with exponential backoff (optional)
        const backoffTime = Math.pow(2, retryCount) * 1000; // Exponential backoff
        setTimeout(() => {
          setRetryCount(retryCount + 1);
          fetchGallery();
        }, backoffTime);
      }
    }
  };

  useEffect(() => {
    if (blog.length < 1) {
      fetchBlog();
    }
    if (gallery.length < 1) {
      fetchGallery();
    }
    // eslint-disable-next-line
  }, []);

  const contextValue = {
    blog: blog,
    BlogLoading: BlogLoading,
    gallery: gallery,
    GalleryLoading: GalleryLoading,
  };

  return (
    <requestContext.Provider value={contextValue}>
      {children}
    </requestContext.Provider>
  );
};
