import React from "react";
import "./FaqAccordion.css";
import Accordion from "react-bootstrap/Accordion";

function FaqAccordion() {
  return (
    <div className="faq-section">
      <div className="container d-flex flex-column align-items-center">
        <h2 className="text-center text-capitalize mb-5">
          Frequently asked questions
        </h2>
        <p className="text-center mb-5">
          Find answers to the most commonly asked questions about Sulazi
          Academy, including admissions, programs, and more.
        </p>
        <Accordion defaultActiveKey="" flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              What is the admission process at Sulazi Academy?
            </Accordion.Header>
            <Accordion.Body>
              The admission process involves filling out the entry forms, paying
              the relevant fees, taking a diagnostic test for prospective
              students, and attending an orientation session.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              What academic programs are offered?
            </Accordion.Header>
            <Accordion.Body>
              Sulazi Academy offers Kindergarten (Ages 2-5) and K1-K12 (Ages
              6-18) with the Accelerated Christian Education (ACE) curriculum,
              focusing on individual character development and Christian values.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              What extracurricular activities are available?
            </Accordion.Header>
            <Accordion.Body>
              The school provides a variety of extracurricular activities
              including sports, arts, and other student-led activities on its
              vast green lawns.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              What is the history of Sulazi Academy?
            </Accordion.Header>
            <Accordion.Body>
              Sulazi Academy started as a home school in 2010 and opened its
              doors to the public in 2021. It aims to transform lives through
              Christ-centered education.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}

export default FaqAccordion;
