import React, { useContext, useEffect } from "react";
import "./Blog.css";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { requestContext } from "../../Context/Requests/RequestContext";

function Blog() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const { blog, BlogLoading } = useContext(requestContext);

  // Skeleton component for loading state
  const BlogSkeleton = () => (
    <div className="row g-4">
      {[...Array(9)].map((_, index) => (
        <div
          key={index}
          className="col-md-6 col-lg-4"
          data-aos="fade-up"
          data-aos-delay={index * 100}
        >
          <Card className="h-100 shadow scale-hover-effect bg-dark text-light border-0">
            <div
              className="skeleton-image"
              style={{ minHeight: "553px", background: "#333" }}
            ></div>
            <Card.Body className="p-md-5 d-flex flex-column align-items-center">
              <div
                className="skeleton-title"
                style={{ width: "80%", height: "2rem", background: "#555" }}
              ></div>
              <div
                className="skeleton-text"
                style={{
                  width: "100%",
                  height: "5rem",
                  background: "#444",
                  marginTop: "1rem",
                }}
              ></div>
            </Card.Body>
          </Card>
        </div>
      ))}
    </div>
  );

  return (
    <div className="blog-page">
      {/* Header Section */}
      <header className="height-75">
        <div
          className="container h-100 d-flex flex-column align-items-center justify-content-center text-light"
          data-aos="fade-down"
        >
          <h1 className="text-center fw-semibold">Sulazi Academy Blog</h1>
          <p
            className="text-center w-75 mb-5"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            Welcome to the Sulazi Academy blog, where we share insights about
            our Christ-centered education, student achievements, and the values
            that shape our academy.
          </p>
        </div>
      </header>

      {/* Blog Section */}
      <div className="bg-body-tertiary py-5">
        <div className="container">
          {/* Show skeleton when loading, otherwise show blog cards */}
          {BlogLoading ? (
            <BlogSkeleton />
          ) : (
            <div className="row g-4">
              {blog?.reverse().map((blog, index) => (
                <div
                  key={blog.id}
                  className="col-md-6 col-lg-4"
                  data-aos="fade-up"
                  data-aos-delay={index * 100}
                >
                  <Link to="/blog" className="text-decoration-none">
                    <Card className="h-100 shadow scale-hover-effect bg-dark text-light border-0">
                      <Card.Img
                        variant="top"
                        src={blog?.img.data_url}
                        style={{
                          minHeight: "553px",
                          maxHeight: "553px",
                          objectFit: "cover",
                        }}
                      />
                      <Card.Body className="p-md-5 d-flex flex-column align-items-center">
                        <Card.Title className="fs-2 mb-4">
                          {blog?.title}
                        </Card.Title>
                        <Card.Text className="text-center">
                          {blog?.description}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Blog;
