import React, { useContext } from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import ChooseSection from "../../components/ChooseSection/ChooseSection";
import StartCoursesImg from "../../utils/courses.jpg";
import FaqAccordion from "../../components/FaqAccordion/FaqAccordion";
import { Card } from "react-bootstrap";
import { requestContext } from "../../Context/Requests/RequestContext";

// Skeleton component for loading state
const BlogSkeleton = () => (
  <div className="row g-4 w-100">
    {[...Array(3)].map((_, index) => (
      <div
        key={index}
        className="col-md-6 col-lg-4"
        data-aos="fade-up"
        data-aos-delay={index * 100}
      >
        <Card className=" h-100 shadow scale-hover-effect bg-dark text-light border-0">
          <div
            className="skeleton-image"
            style={{ minHeight: "553px", background: "#333" }}
          ></div>
          <Card.Body className="p-md-5 d-flex flex-column align-items-center">
            <div
              className="skeleton-title"
              style={{ width: "80%", height: "2rem", background: "#555" }}
            ></div>
            <div
              className="skeleton-text"
              style={{
                width: "100%",
                height: "5rem",
                background: "#444",
                marginTop: "1rem",
              }}
            ></div>
          </Card.Body>
        </Card>
      </div>
    ))}
  </div>
);

function Home() {
  const { blog, BlogLoading } = useContext(requestContext);

  return (
    <div className="home-page" style={{ overflowX: "hidden" }}>
      {/* Header Section */}
      <header
        className="h-100 min-vh-100 d-flex align-items-center text-light"
        data-aos="fade-up"
      >
        <div className="container d-flex flex-column align-items-center">
          <h2>Welcome To</h2>
          <h1 className="text-center fw-semibold">Sulazi Academy</h1>
          <p>
            Transforming lives through Christ-centered education. Our motto:
            Achieving Excellence Together.
          </p>
          <div
            className="d-flex flex-column flex-sm-row align-items-center"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <Link to="/courses">
              <button
                type="button"
                className="btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0"
              >
                Our Programs
              </button>
            </Link>
            <Link to="/contact">
              <button
                type="button"
                className="btn btn-outline-light btn-lg mx-0 mx-sm-2 my-2 my-sm-0"
              >
                Contact Us
              </button>
            </Link>
          </div>
        </div>
      </header>

      {/* Choose Section */}
      <div className="py-5" data-aos="fade-right">
        <ChooseSection />
      </div>

      {/* Start Courses Section */}
      <div className="py-5 bg-light">
        <div className="container">
          <div
            className="row d-flex align-items-center justify-content-around"
            data-aos="fade-up"
          >
            <div className="col-lg-5">
              <h2 className="text-capitalize">2024 Start Courses</h2>
              <p>
                Admissions are open for our Kindergarten and K1-K12 programs.
                Explore our Christ-centered curriculum and individualized
                learning experience.
              </p>
              <Link to="/courses">
                <button
                  type="button"
                  className="btn btn-danger btn-lg mx-0 mx-sm-2 my-2 my-sm-0"
                  data-aos="zoom-in"
                >
                  Learn More
                </button>
              </Link>
            </div>
            <div className="col-lg-5 mt-5 mt-lg-0" data-aos="zoom-in">
              <img
                src={StartCoursesImg}
                className="img-fluid"
                alt="Start Courses"
              />
            </div>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="py-5" data-aos="zoom-out">
        <FaqAccordion />
      </div>

      {/* Blog Section */}
      <div className="blog-section text-light py-5">
        <div className="container d-flex flex-column align-items-center">
          <h2 className="text-center text-capitalize mb-5">
            Latest on the Blog
          </h2>
          {BlogLoading ? (
            <BlogSkeleton />
          ) : (
            <div className="row g-4">
              {blog
                .reverse()
                .slice(0, 3)
                .map((blog, index) => (
                  <div key={index} className="col-md-6 col-lg-4">
                    <Link to="/blog" className="text-decoration-none">
                      <Card
                        className="h-100 shadow scale-hover-effect"
                        data-aos="fade-up"
                      >
                        <Card.Img
                          variant="top"
                          src={blog?.img.data_url}
                          style={{
                            minHeight: "553px",
                            maxHeight: "553px",
                            objectFit: "cover",
                          }}
                        />
                        <Card.Body className="p-md-5">
                          <Card.Title>{blog?.title}</Card.Title>
                          <Card.Text>{blog?.description}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Link>
                  </div>
                ))}
            </div>
          )}
          <Link to="/blog">
            <button
              type="button"
              className="btn btn-danger btn-lg mt-5"
              data-aos="zoom-in"
            >
              Read More Blogs
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Home;
